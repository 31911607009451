
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarRelacaoPessoa from '@/components/Cadastros/Pessoas/SelecionarRelacaoPessoa.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoTipoContato from '@/servicos/Cadastros/Pessoas/ServicoTipoContato';
import { ITipoContato, ITipoContatoEmpresa, ITipoContatoRelacaoPessoa } from '@/models/Entidades/Cadastros/Pessoas/Contatos/ITipoContato';

export default defineComponent({
  name: 'TipoContatoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarRelacaoPessoa,
    Icone,
  },
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, defineEmpresasSelecionadasCadastroCompartilhado, apresentarMensagemAlerta,
      preencherPermissoesDados, verificaConceitoParaApresentarEmpresas, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoTipoContato = new ServicoTipoContato();
    telaBase.identificadorRecurso = 'CADASTRO_TIPOS_CONTATOS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_TIPOS_CONTATOS';

    const state = reactive({
      tipoContato: {} as ITipoContato,
      codigosRelacoes: [] as number[],
    });

    function objetoInicial() {
      state.tipoContato = {} as ITipoContato;
      state.tipoContato.ativo = true;
      state.codigosRelacoes = [];
    }

    async function salvar(salvarNovo: boolean) {
      if (state.codigosRelacoes.length === 0) {
        apresentarMensagemAlerta('Selecione ao menos um tipo de relação para mostrar o tipo de contato!');
        return;
      }
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      state.tipoContato.empresas = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const contatoEmpresa: ITipoContatoEmpresa = { codigo: 0, codigoTipoContato: state.tipoContato.codigo, codigoEmpresa };
          state.tipoContato.empresas.push(contatoEmpresa);
        });
      }

      state.tipoContato.relacoesPessoa = [];
      if (state.codigosRelacoes.length > 0) {
        state.codigosRelacoes.forEach((tipoRelacao) => {
          const contatoRelacao: ITipoContatoRelacaoPessoa = { codigo: 0, codigoTipoContato: state.tipoContato.codigo, tipoRelacao };
          state.tipoContato.relacoesPessoa.push(contatoRelacao);
        });
      }

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoTipoContato.incluir(state.tipoContato);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoTipoContato.alterar(state.tipoContato);
      }
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.tipoContato = await servicoTipoContato.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          telaBase.empresasSelecionadas = [];
          if (state.tipoContato.empresas.length > 0) {
            state.tipoContato.empresas.forEach((cidadeEmpresa) => {
              telaBase.empresasSelecionadas.push(cidadeEmpresa.codigoEmpresa);
            });
          }

          if (state.tipoContato.relacoesPessoa.length > 0) {
            state.tipoContato.relacoesPessoa.forEach((relacaoPessoa) => {
              state.codigosRelacoes.push(relacaoPessoa.tipoRelacao);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
    };
  },
});
