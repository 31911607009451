import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tela_padrao_crud = _resolveComponent("tela-padrao-crud")!
  const _component_tipo_contato_modal = _resolveComponent("tipo-contato-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_tela_padrao_crud, {
      ref: "refCrudTipoContato",
      estrutura: _ctx.crudBase.estrutura,
      servicoAPI: _ctx.servicoTipoContato,
      onExibirModal: _ctx.exibirModal,
      onSincronizaTelaCrud: _ctx.sincronizaTelaCrud
    }, null, 8, ["estrutura", "servicoAPI", "onExibirModal", "onSincronizaTelaCrud"]),
    _createVNode(_component_tipo_contato_modal, {
      visivel: _ctx.crudBase.exibirModal,
      "onUpdate:visivel": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.crudBase.exibirModal) = $event)),
      operacao: _ctx.crudBase.operacao,
      "onUpdate:operacao": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.crudBase.operacao) = $event)),
      onSincronizarRegistro: _ctx.sincronizarRegistro
    }, null, 8, ["visivel", "operacao", "onSincronizarRegistro"])
  ], 64))
}