
import {
  defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { EStatusRetornoBancario } from '@/models/Enumeradores/Financeiro/EStatusRetornoBancario';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import { IDTOPessoa } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoa';
import ServicoImportacaoPessoas from '@/servicos/ImportacoesRegistros/ServicoImportacaoPessoas';
import SelecionarCidade from '@/components/Cadastros/Localizacoes/Cidades/SelecionarCidade.vue';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';

export default defineComponent({
  name: 'ImportacaoRegistrosExcelModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    relacao: {
      type: String,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    PreviewPdf,
    SelecionarCidade,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso, apresentarMensagemErro,
    } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoSistema = new ServicoSistema();
    const servicoImportacaoPessoas = new ServicoImportacaoPessoas();
    const servicoParametros = new ServicoParametros();
    servicoImportacaoPessoas.requisicaoSistema();
    servicoParametros.requisicaoSistema();

    const state = reactive({
      pessoas: [] as IDTOPessoa[],
      cacheAutomatico: {} as ISalvamentoAutomatico,
      cacheDisponivel: false,
      nomeArquivo: '',
      buscaRapida: '',
      enviandoArquivo: false,
      painelUpload: 1,
      arquivosPdf: [] as IArquivoPdf[],
      codigoCidade: 0,
      nomeCidade: '',
    });

    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Index', dataIndex: 'index', key: 'Index', position: 1, visible: false,
          },
          {
            title: 'Pessoa', dataIndex: 'pessoa', key: 'Pessoa', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Index', dataIndex: 'index', key: 'Index', position: 1, visible: false,
          },
          {
            title: 'Tipo de Pessoa', dataIndex: 'descricaoTipoPessoa', key: 'DescricaoTipoPessoa', position: 2, visible: true, ellipsis: true, width: 100, align: 'center',
          },
          {
            title: 'CPF/CNPJ', dataIndex: 'cpfCnpj', key: 'CpfCnpj', position: 3, visible: true, ellipsis: true, width: 150, align: 'center',
          },
          {
            title: 'Relações', dataIndex: 'descricaoRelacoes', key: 'DescricaoRelacoes', position: 4, visible: true, width: 250, ellipsis: true, align: 'center',
          },
          {
            title: 'Tipo de Atividade', dataIndex: 'descricaoTipoAtividade', key: 'DescricaoTipoAtividade', position: 5, visible: true, width: 150, ellipsis: true, align: 'center',
          },
          {
            title: 'Nome', dataIndex: 'nomeCompletoRazaoSocial', key: 'NomeCompletoRazaoSocial', position: 6, visible: true, ellipsis: true, width: 200,
          },
          {
            title: 'Nome Fantasia', dataIndex: 'nomeAbreviadoNomeFantasia', key: 'NomeAbreviadoNomeFantasia', position: 7, visible: true, ordering: true, ellipsis: true, width: 200, align: 'left',
          },
          {
            title: 'Telefone', dataIndex: 'telefone', key: 'Telefone', customRenderRow: ECustomRenderRow.MascararTelefoneCelular, position: 8, visible: true, width: 100, ellipsis: true, align: 'left',
          },
          {
            title: 'E-mail', dataIndex: 'email', key: 'Email', position: 9, visible: true, width: 150, ellipsis: true, align: 'left',
          },
          {
            title: 'CEP', dataIndex: 'cep', key: 'Cep', position: 10, visible: true, align: 'left', width: 80,
          },
          {
            title: 'Endereço/Avenida/Rua', dataIndex: 'endereco', key: 'Endereco', position: 11, visible: true, align: 'left', width: 150,
          },
          {
            title: 'Número', dataIndex: 'numero', key: 'Numero', position: 12, visible: true, align: 'left', width: 100,
          },
          {
            title: 'Complemento', dataIndex: 'complemento', key: 'Complemento', position: 13, visible: true, align: 'left', width: 150,
          },
          {
            title: 'Bairro', dataIndex: 'bairro', key: 'Bairro', position: 14, visible: true, align: 'left', width: 150,
          },
          {
            title: 'Cidade/UF', dataIndex: 'nomeCidade', key: 'NomeCidade', position: 15, visible: true, align: 'left', width: 150,
          },
        ];
      }
    }

    async function limparTela() {
      state.pessoas = [] as IDTOPessoa[];
      gradeBase.codigosSelecionados = [] as number[];
      state.nomeArquivo = '';
      state.buscaRapida = '';
      state.enviandoArquivo = false;
      state.painelUpload = 1;
      state.codigoCidade = 0;
      state.nomeCidade = '';
      preencherColunas();
    }

    function obterIdentificadorCache(): string {
      return `IMPORTACAO_PESSOAS_EXCEL_${storeSistema.getters.codigoUsuarioAutenticado()}`;
    }

    function salvarCache() {
      const identificador = obterIdentificadorCache();
      state.cacheAutomatico.data = UtilitarioData.obterDataAtual();
      state.cacheAutomatico.codigoEmpresa = props.empresa;
      state.cacheAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.cacheAutomatico.identificador = telaBase.identificadorRecurso;
      state.cacheAutomatico.dados = JSON.stringify(state.pessoas);
      servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}`, state.cacheAutomatico);
      state.cacheDisponivel = false;
    }

    async function recuperarCache() {
      if (UtilitarioGeral.valorValido(state.cacheAutomatico.dados)) {
        const cache = JSON.parse(state.cacheAutomatico.dados);
        if (UtilitarioGeral.objetoValido(cache)) {
          servicoSistema.removerSalvamentoAutomaticoLocalStorage(obterIdentificadorCache());
          state.pessoas = cache;
          state.cacheDisponivel = false;
          state.painelUpload = 0;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
      }
    }

    async function verificarCache() {
      const identificador = obterIdentificadorCache();
      const salvamentoAutomaticoLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(identificador);
      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
        state.cacheAutomatico = salvamentoAutomaticoLocalStorage;
        if (UtilitarioData.validaDataPreenchida(state.cacheAutomatico.data)) {
          const cache = JSON.parse(state.cacheAutomatico.dados);
          state.cacheAutomatico = cache;
          state.cacheDisponivel = true;
        }
      }
    }

    function selecionarArquivoUpload() {
      const elemento = document.getElementById('selecionarArquivo');
      if (elemento !== null) {
        elemento.click();
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };

    async function enviarArquivoLeitura(arquivo: any) {
      limparTela();
      state.enviandoArquivo = true;
      const retorno = await servicoImportacaoPessoas.lerArquivoPessoas(props.empresa, arquivo);
      state.nomeArquivo = arquivo.name;
      if (UtilitarioGeral.validaLista(retorno)) {
        if (!UtilitarioGeral.validaLista(retorno)) {
          apresentarMensagemAlerta('Não foi possível ler o arquivo selecionado!');
        } else {
          state.pessoas = retorno;
          state.pessoas.forEach((t, index) => {
            state.pessoas[index].index = index;
            state.pessoas[index].visivel = true;
          });
          state.painelUpload = 0;
        }
      } else {
        apresentarMensagemAlerta('Ocorreu um erro ao ler o arquivo');
      }
      state.enviandoArquivo = false;
    }

    async function uploadArquivoSelecionado(event: any) {
      await enviarArquivoLeitura(event.target.files[0]);
    }

    async function uploadArquivoArrastado(event: any) {
      await enviarArquivoLeitura(event.dataTransfer.files[0]);
    }

    function verificarFiltro() {
      state.pessoas.forEach((p, index) => {
        if (state.buscaRapida === '' || state.buscaRapida === undefined) {
          state.pessoas[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.cpfCnpj) && p.cpfCnpj.toLowerCase().includes(state.buscaRapida)) {
          state.pessoas[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.nomeCompletoRazaoSocial) && p.nomeCompletoRazaoSocial.toLowerCase().includes(state.buscaRapida)) {
          state.pessoas[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.nomeAbreviadoNomeFantasia) && p.nomeAbreviadoNomeFantasia.toLowerCase().includes(state.buscaRapida)) {
          state.pessoas[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.descricaoTipoAtividade) && p.descricaoTipoAtividade.toLowerCase().includes(state.buscaRapida)) {
          state.pessoas[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.telefone) && p.telefone.toLowerCase().includes(state.buscaRapida)) {
          state.pessoas[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.email) && p.email.toLowerCase().includes(state.buscaRapida)) {
          state.pessoas[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.bairro) && p.bairro.toLowerCase().includes(state.buscaRapida)) {
          state.pessoas[index].visivel = true;
        } else if (UtilitarioGeral.valorValido(p.nomeCidade) && p.nomeCidade.toLowerCase().includes(state.buscaRapida)) {
          state.pessoas[index].visivel = true;
        } else {
          state.pessoas[index].visivel = false;
        }
      });
    }

    function alterarCidades() {
      state.pessoas.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.pessoas[index].codigoCidade = state.codigoCidade;
          state.pessoas[index].nomeCidade = state.nomeCidade;
        }
      });
    }

    function realizarAjustes() {
      if (gradeBase.codigosSelecionados.length === 0) {
        apresentarMensagemAlerta('nenhuma pessoa selecionada!');
        return;
      }

      if (state.codigoCidade > 0) {
        alterarCidades();
      }

      state.codigoCidade = 0;
      gradeBase.codigosSelecionados = [] as number[];
    }

    function validarPessoas() {
      let retorno = true;

      state.pessoas.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          if (!UtilitarioGeral.valorValido(p.descricaoTipoPessoa) && retorno) {
            apresentarMensagemAlerta('Umq das pessoas está com o tipo de atividade inválido!');
            retorno = false;
          }

          if (!UtilitarioGeral.valorValido(p.cpfCnpj) && retorno) {
            apresentarMensagemAlerta('Uma das pessoas está com o CPF/CNPJ inválido!');
            retorno = false;
          }

          if (!UtilitarioGeral.valorValido(p.descricaoTipoAtividade) && p.tipoPessoa === ETipoPessoa.Juridica && retorno) {
            apresentarMensagemAlerta('Uma das pessoas está com o tipo de atividade inválido!');
            retorno = false;
          }

          if (!UtilitarioGeral.valorValido(p.nomeAbreviadoNomeFantasia) && p.tipoPessoa === ETipoPessoa.Juridica && retorno) {
            apresentarMensagemAlerta('Uma das pessoas está com o nome fantasia inválido!');
            retorno = false;
          }

          if (!UtilitarioGeral.valorValido(p.cep) && retorno) {
            apresentarMensagemAlerta('Uma das pessoas está com o CEP inválido!');
            retorno = false;
          }

          if (!UtilitarioGeral.valorValido(p.endereco) && retorno) {
            apresentarMensagemAlerta('Uma das pessoas está com o endereço inválido!');
            retorno = false;
          }

          if (!UtilitarioGeral.validaCodigo(p.codigoCidade) && retorno) {
            apresentarMensagemAlerta('Uma das pessoas está com a cidade inválida!');
            retorno = false;
          }
        }
      });
      return retorno;
    }

    async function baixarArquivo() {
      const retorno = await servicoImportacaoPessoas.obterArquivoPadraoPessoas();
      if (UtilitarioGeral.valorValido(retorno)) {
        UtilitarioGeral.downloadArquivo(retorno);
      } else {
        apresentarMensagemErro('Ocorreu um erro ao obter a planilha de exemplo!');
      }
    }

    async function importarPessoas() {
      if (gradeBase.codigosSelecionados.length === 0) {
        apresentarMensagemAlerta('nenhuma pessoa selecionada!');
        return;
      }

      if (!validarPessoas()) {
        return;
      }

      const pessoasSelecionados = [] as IDTOPessoa[];
      state.pessoas.forEach((p, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          pessoasSelecionados.push(p);
        }
      });

      servicoImportacaoPessoas.importarPessoas(props.empresa, props.relacao, pessoasSelecionados);
      modalBase.retornoRequisicao = {} as IRetornoRequisicao;
      modalBase.retornoRequisicao.mensagem = 'As pessoas serão importadas em segundo plano assim que a importação for finalizada você será notificado!';
      modalBase.exibirRequisicaoModal = true;
      emit('confirmacao');
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        verificarCache();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      window,
      EStatusRetornoBancario,
      EStatusTituloFinanceiro,
      UtilitarioData,
      UtilitarioMascara,
      UtilitarioGeral,
      mudarSelecao,
      selecionarArquivoUpload,
      uploadArquivoSelecionado,
      salvarCache,
      uploadArquivoArrastado,
      recuperarCache,
      verificarFiltro,
      importarPessoas,
      baixarArquivo,
      realizarAjustes,
      ECustomRenderRow,
    };
  },
});
