import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tela_padrao_crud = _resolveComponent("tela-padrao-crud")!
  const _component_importacao_registros_excel_modal = _resolveComponent("importacao-registros-excel-modal")!
  const _component_pessoa_modal = _resolveComponent("pessoa-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_tela_padrao_crud, {
      ref: "refTelaPadraoCrud",
      estrutura: _ctx.crudBase.estrutura,
      servicoAPI: _ctx.servicoFornecedor,
      onExibirModal: _ctx.exibirModal,
      "exibir-importacao": _ctx.state.exibirImportacaoExcel,
      "onUpdate:exibirImportacao": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.exibirImportacaoExcel) = $event)),
      onSincronizaTelaCrud: _ctx.sincronizaTelaCrud
    }, null, 8, ["estrutura", "servicoAPI", "onExibirModal", "exibir-importacao", "onSincronizaTelaCrud"]),
    _createVNode(_component_importacao_registros_excel_modal, {
      visivel: _ctx.state.exibirImportacaoExcel,
      "onUpdate:visivel": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.exibirImportacaoExcel) = $event)),
      relacao: 'fornecedores',
      empresa: _ctx.storeSistema.getters.codigoEmpresaOperacao()
    }, null, 8, ["visivel", "empresa"]),
    _createVNode(_component_pessoa_modal, {
      relacaoPrincipalOperacao: _ctx.ETipoRelacaoPessoa.Fornecedor,
      tituloModal: "Cadastro de Fornecedor",
      visivel: _ctx.crudBase.exibirModal,
      "onUpdate:visivel": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.crudBase.exibirModal) = $event)),
      operacao: _ctx.crudBase.operacao,
      "onUpdate:operacao": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.crudBase.operacao) = $event)),
      servicoAPI: _ctx.servicoFornecedor,
      onSincronizarRegistro: _ctx.sincronizarRegistro
    }, null, 8, ["relacaoPrincipalOperacao", "visivel", "operacao", "servicoAPI", "onSincronizarRegistro"])
  ], 64))
}